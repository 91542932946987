import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { isEmptyRedirect } from "utils/redirect"
import ContactSection from "components/content/ContactSection"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import Article from "components/content/Article"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

const ArticlePage = ({
  data: {
    api: { blogArticle },
  },
  pageContext: { language },
}) => {
  const { articleTitle } = blogArticle || {}
  isEmptyRedirect(articleTitle)

  return (
    <>
      {articleTitle && (
        <Layout language={language}>
          <SEO title={`Article - ${articleTitle}`} />
          <Article articleData={blogArticle} />
          <ContactSection
            trackingOptions={{
              category: trackingCategoriesHelper.BLOG_ARTICLE,
              action: "Click",
              label: `${trackingCategoriesHelper.BLOG_ARTICLE} button(Send message)`,
            }}
          />
        </Layout>
      )}
    </>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      blogArticle: PropTypes.shape({
        articleTitle: PropTypes.string,
        author: PropTypes.string,
        slug: PropTypes.string,
        sourceLink: PropTypes.string,
        image: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        body: PropTypes.string,
        publicationDate: PropTypes.string,
        sourceTitle: PropTypes.string,
        id: PropTypes.string,
        keepReading: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            articleTitle: PropTypes.string,
            slug: PropTypes.string,
            image: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            cardTeaser: PropTypes.string,
          })
        ),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export const query = graphql`
  query ArticlePageData($apiLocale: API_Locale, $blogSlug: String!) {
    api {
      blogArticle(slug: $blogSlug, language: $apiLocale) {
        articleTitle
        author
        sourceLink
        image
        body
        publicationDate
        sourceTitle
        id
        keepReading(language: $apiLocale) {
          id
          articleTitle
          image
          cardTeaser
          slug
        }
      }
    }
  }
`

export default ArticlePage
