import React from "react"
import PropTypes from "prop-types"
import { Link, useIntl } from "gatsby-plugin-intl"

import DOMPurify from "dompurify"

import formatDate from "utils/formatDate"
import paths from "utils/paths"
import browserWindow from "utils/browserWindow"
import getMediaUrl from "utils/getMediaUrl"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import ArticleCard from "components/molecules/ArticleCard"
import BtnLink from "components/atoms/BtnLink"
import Button from "components/atoms/Button"
import H from "components/atoms/H"

import {
  StyledWrapper,
  StyledArticleImg,
  StyledLabelWrapper,
  StyledDate,
  articleHeadingStyles,
  StyledSource,
  StyledAuthor,
  StyledArticleTextContainer,
  originaArticleLinkStyles,
  keepReadingHeadingStyles,
  StyledArticleListWrapper,
  StyledButtonsWrapper,
} from "./Article.styles"

const Article = ({
  articleData: {
    articleTitle,
    author,
    sourceLink,
    image,
    body,
    publicationDate,
    sourceTitle,
    keepReading,
  },
}) => {
  const { blog } = paths
  const { formatMessage } = useIntl()
  const domPurify = DOMPurify(browserWindow)

  const handleTackCustomEventTrigger = (trackingOptions) => {
    if (trackingOptions) trackCustomEventTrigger(trackingOptions)
  }

  return (
    <StyledWrapper>
      <StyledArticleImg src={getMediaUrl(image)} />
      <StyledLabelWrapper>
        <StyledDate>{formatDate(publicationDate)}</StyledDate>
        <H as="h2" extraStyles={articleHeadingStyles}>
          {articleTitle}
        </H>
        {sourceTitle && sourceLink && (
          <StyledSource
            href={sourceLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> {formatMessage({ id: "pages.article.source" })}: </span>
            {sourceTitle}
          </StyledSource>
        )}
        {author && (
          <StyledAuthor>
            <span>{formatMessage({ id: "pages.article.author" })}: </span>
            {author}
          </StyledAuthor>
        )}
      </StyledLabelWrapper>
      {domPurify && !!domPurify.sanitize && body && (
        <StyledArticleTextContainer
          dangerouslySetInnerHTML={{ __html: domPurify.sanitize(body) }}
        />
      )}
      <StyledButtonsWrapper>
        <Button type="secondary" to={blog}>
          {formatMessage({ id: "pages.article.return" })}
        </Button>
        {sourceLink && (
          <BtnLink
            target="_blank"
            rel="noopener noreferrer"
            to={sourceLink}
            linkStyles={originaArticleLinkStyles}
          >
            {formatMessage({ id: "pages.article.viewOriginal" })}
          </BtnLink>
        )}
      </StyledButtonsWrapper>

      <H as="h2" extraStyles={keepReadingHeadingStyles}>
        {formatMessage({ id: "pages.article.keepReading" })}
      </H>

      <StyledArticleListWrapper>
        {keepReading.map((article) => (
          <Link
            to={`${paths.article(article.slug)}`}
            key={article.id}
            data-testid={`articleBlock${article.id}`}
            onClick={() => {
              handleTackCustomEventTrigger({
                category: trackingCategoriesHelper.BLOG_ARTICLE,
                action: "Click",
                label: `${trackingCategoriesHelper.BLOG_ARTICLE} Link(${article.slug})`,
              })
            }}
          >
            <ArticleCard article={article} />
          </Link>
        ))}
      </StyledArticleListWrapper>
    </StyledWrapper>
  )
}

Article.propTypes = {
  articleData: PropTypes.shape({
    articleTitle: PropTypes.string,
    author: PropTypes.string,
    sourceLink: PropTypes.string,
    image: PropTypes.oneOfType([
      PropTypes.shape({
        original: PropTypes.string,
        large: PropTypes.string,
        medium: PropTypes.string,
        small: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    body: PropTypes.string,
    publicationDate: PropTypes.string,
    sourceTitle: PropTypes.string,
    id: PropTypes.string,
    keepReading: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        articleTitle: PropTypes.string,
        slug: PropTypes.string,
        image: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        cardTeaser: PropTypes.string,
      })
    ),
  }).isRequired,
}
export default Article
