import styled, { css } from "styled-components"
import Image from "components/atoms/Image"

const infoLabelStyles = css`
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: 1.67;
  letter-spacing: 0.25px;
`

const infoTextStyles = css`
  ${infoLabelStyles}
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.secondary01};

  > span {
    font-size: inherit;
    color: ${({ theme }) => theme.color.secondary02};
    font-weight: normal;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 7.2rem ${({ theme }) => theme.layout.mobilePadding} 6.4em;

  ${({ theme }) => theme.mq.medium} {
    padding: 9.6rem ${({ theme }) => theme.layout.mobilePadding} 16rem;
  }
`

const StyledArticleImg = styled(Image)`
  width: 100%;
  height: 48rem;
  object-fit: cover;
  order: 2;

  ${({ theme }) => theme.mq.medium} {
    order: 1;
  }
`

const StyledLabelWrapper = styled.div`
  max-width: 56.8rem;
  margin: 3.2rem 0 2.4rem;
  order: 1;

  ${({ theme }) => theme.mq.medium} {
    order: 2;
    margin: 4rem auto 0;
  }
`

const StyledDate = styled.span`
  ${infoLabelStyles}
`

const articleHeadingStyles = css`
  margin: 0.8rem 0 2.4rem;

  ${({ theme }) => theme.mq.medium} {
    margin: 2.4rem 0;
  }
`

const StyledSource = styled.a`
  ${infoTextStyles}
`

const StyledAuthor = styled.div`
  ${infoTextStyles}
`

const StyledArticleTextContainer = styled.div`
  padding-top: 2.4rem;
  padding-bottom: 3.2rem;
  max-width: 56.8rem;
  margin: 0 auto;
  order: 3;

  > p {
    margin-bottom: 2.4rem;
  }

  img,
  video {
    max-width: 100%;
  }

  ${({ theme }) => theme.mq.medium} {
    padding-top: 6.4rem;

    > p {
      margin-bottom: 4.8rem;
    }
  }
`
const originaArticleLinkStyles = css`
  margin: 4rem 0;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 4;
  margin-bottom: 4.8rem;

  ${({ theme }) => theme.mq.medium} {
    margin-bottom: 0;
  }
`
const keepReadingHeadingStyles = css`
  align-self: baseline;
  margin-bottom: 2.4rem;
  order: 5;
`
const StyledArticleListWrapper = styled.div`
  display: grid;
  grid-gap: 3.2rem;
  order: 6;

  ${({ theme }) => theme.mq.medium} {
    grid-template-columns: repeat(3, minmax(20rem, 36.8rem));
  }
`
export {
  StyledWrapper,
  StyledArticleImg,
  StyledLabelWrapper,
  StyledDate,
  articleHeadingStyles,
  StyledSource,
  StyledAuthor,
  StyledArticleTextContainer,
  originaArticleLinkStyles,
  StyledArticleListWrapper,
  keepReadingHeadingStyles,
  StyledButtonsWrapper,
}
